exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-editor-js": () => import("./../../../src/pages/editor.js" /* webpackChunkName: "component---src-pages-editor-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index_old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-styles-js": () => import("./../../../src/pages/styles.js" /* webpackChunkName: "component---src-pages-styles-js" */),
  "component---src-templates-storyblok-entry-js": () => import("./../../../src/templates/storyblok-entry.js" /* webpackChunkName: "component---src-templates-storyblok-entry-js" */),
  "component---src-templates-storyblok-homepage-js": () => import("./../../../src/templates/storyblok-homepage.js" /* webpackChunkName: "component---src-templates-storyblok-homepage-js" */),
  "component---src-templates-storyblok-loop-js": () => import("./../../../src/templates/storyblok-loop.js" /* webpackChunkName: "component---src-templates-storyblok-loop-js" */)
}

